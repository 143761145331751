import React from "react"

export default ({ pageContext: { groups, logoPublicURLs, randomShortcuts } }) => {
  const ShortcutTD = ({ shortcut }) => {
    const steps = shortcut.steps.map((step, i) => {
      let classes = []
      let style = { margin: "0 4px" }
      if (step.type === "key") {
        classes.push('shortcutKey')
        style = {}
      }
      return <span key={i} className={classes.join(' ')} style={style}>{step.value}</span>
    })
    return (
      <>
        { steps }
        <br />
        <span style={{display: "block", marginTop: "8px"}}>
          { shortcut.command }
        </span>
      </>
    )
  }

  const Shortcuts = ({ appName, appKeyname, shortcut }) => {
    const appCmdSpaceUrl = `https://www.randomshortcuts.com/${ appKeyname }`
    const logoURL = `https://www.randomshortcuts.com${logoPublicURLs[appKeyname]}`
    return (
      <table style={{ width: "524px" }}>
        <tbody>
          <tr style={{ borderBottom: "1px solid #dadada" }}>
            <td width="75" style={{ padding: "15px 0"}}>
              <img src={logoURL} width="45" height="45" alt={appName} />
            </td>
            <td width="75">
              <a
                style={{color: "#2369fd"}}
                href={ appCmdSpaceUrl }>
                { appName }
              </a>
            </td>
            <td style={{padding: "10px", width: "394px"}}>
              <ShortcutTD shortcut={shortcut} />
						</td>
          </tr>
        </tbody>
      </table>
    )
  }

  const FooterTable = () => {
    const buttonStyle = {
      background: "black",
      padding: "10px",
      borderRadius: "10px",
      color: "white",
      margin: "10px 0 20px",
      display: "inline-block"
    }

    return (
      <table style={{marginTop: "35px"}}>
        <tbody>
          <tr>
            <td style={{width: "282px"}}>
              <div className="tableFooter">
                <div className="shortcutTitle">Don't see an app you use?</div>
                <a style={buttonStyle} href="https://forms.gle/gSYpNL34Qc1ghCGH7">Request an app</a>
              </div>
            </td>
            <td style={{width: "282px"}}>
              <div className="tableFooter">
                <div className="shortcutTitle">Too many apps?</div>
                <a style={buttonStyle} href="https://us20.admin.mailchimp.com/campaigns/wizard/neapolitan/*%7CUPDATE_PROFILE%7C*">Adjust your preferences</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    )
  }

  const categories = groups.map((group, i) => {
    const categoryName = group.fieldValue
    return (
      <>
        *|INTERESTED:Category:{categoryName.trim()}|*
        <div style={{
        "fontSize": "24px",
        "marginTop": "35px",
        "marginBottom": "8px",
        "fontWeight": "bold",
        "color": "black"
      }}>
        {categoryName}
      </div>
      {group.nodes.map((node, i) => {
        return <Shortcuts key={i} appName={node.name} appKeyname={node.keyname} shortcut={randomShortcuts[node.keyname]} />
      })}
      *|END:INTERESTED|*
    </>
    )
  })

  return (
    <div className="cs-random-news">
      <div id="shortcuts">
        { categories }
        <FooterTable />
      </div>
    </div>
  )
}
